.hi,
.name {
  color: white;
  font-size: 5vw;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0.5vw;
}
.name:after {
  content: "Arsalaan";
  border-bottom: 3px var(--primary) solid;
}
.title-holder {
  position: relative;
  /* outline: 1px solid green; */
  width: 40vw;
  margin-left: 15vw;
  margin-top: 12vw;
}

.sidebarContainer {
  position: absolute;
  top: 1vw;
  left: -3vw;
  width: 0.5vw;
  height: 14vw;
  background-color: var(--primary);
}

.githubIcon {
  position: absolute;
  top: 1vw;
  left: -5vw;
  color: var(--primary);
  transition: 0.2s all ease-in-out;
  font-size: 4vw;
}

.githubIcon:hover {
  color: white;
  cursor: pointer;
}

.linkedinIcon {
  position: absolute;
  top: 8vw;
  left: -5vw;
  color: var(--primary);
  transition: 0.2s all ease-in-out;
  font-size: 4vw;
}

.linkedinIcon:hover {
  color: white;
  cursor: pointer;
}

.typewriter {
  color: #15c06d;
  font-size: 5vw;
}
.cursor {
  color: var(--primary);
  font-size: 5vw;
  animation: pulse 1s infinite;
}
.homeContainer {
  /* outline: 3px solid blue; */
  position: relative;
  width: 100%;
  height: 40%;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .homeContainer {
    height: 70vw;
  }
  .title-holder {
    position: absolute;
    top: 35vw;
    left: 50%;
    transform: translate(-70%, 0);
    width: 60vw;
  }
  .hi,
  .name {
    font-size: 7vw;
  }
  .typewriter {
    font-size: 7vw;
  }
  .sidebarContainer {
    position: absolute;
    top: 3.5vw;
    left: -3vw;
    width: 0.5vw;
    height: 15vw;
    background-color: var(--primary);
  }
}
