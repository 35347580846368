@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

body {
  font-family: "Poppins";
}
.canvasContainer {
  width: 100%;
  height: 50em;
}
html {
  background-color: #262626;
}
.bg {
  position: absolute;
  height: 100%;
  width: 100%;
}
