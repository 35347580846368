.flexContainer {
  display: flex;
  justify-content: center;
}
.amTitle {
  position: relative;
  color: white;
  text-align: center;
  font-size: 6vw;
  margin-top: 5vw;
}

.amTitle::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 0.07em;
  background-color: var(--primary);
}

.amText {
  color: white;
  text-align: center;
  font-size: 2vw;
  margin-top: 1vw;
}
.highlightText {
  color: var(--primary);
}
.amContainer {
  height: 50vw;
  width: 100%;
  /* outline: 1px solid blue; */
}
.sectionTitle {
  margin: 3vw;
  color: white;
  text-align: center;
  font-size: 2vw;
}
