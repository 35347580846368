.container {
  position: absolute;
  float: right;
  /* border: 1px solid red; */
  width: 40vw;
  height: 70vw;
  right: 7vw;
  top: -28vw;
  z-index: -1;
}

@media only screen and (max-width: 1000px) {
  .container {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    height: 90vw;
  }
}
