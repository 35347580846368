.overallContainer {
  /* outline: 1px solid red; */
}
.project {
  width: 100%;
  display: flex;
  justify-content: center;
  /* outline: 1px solid green; */
}
.projectTitle::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 0.07em;
  background-color: var(--primary);
}
.projectTitle {
  position: relative;
  margin-top: 20vw;
  color: white;
  text-align: center;
  font-size: 6vw;
}
.tempProj {
  margin-top: 5vw;
}
.projectContainer {
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  display: flex;
  justify-content: space-around;
  /* outline: 1px solid green; */
}
.projectImage {
  height: auto;
  width: 30vw;
  margin-top: 5vw;
}
.projectText {
  font-size: 1vw;
  position: relative;
  color: white;
  margin-top: 4vw;
  margin-left: 2vw;
}
.projectButton {
  font-size: 1.5vw;
  font-family: Poppins;
  color: white;
  background-color: var(--primary);
  border: none;
  margin-top: 1vw;
  cursor: pointer;
  border-radius: 0.5em;
  width: 5vw;
  height: 3vw;
}
.projectDesc {
  font-size: 1.4vw;
}
