:root {
  --primary: #15c06d;
  --solid: #262626;
}
.nav {
  margin-top: 0vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
ul {
  list-style-type: none;
}
li {
  position: relative;
  display: inline-block;
  margin-right: 5vw;
}
li p {
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: white;
  text-align: center;
  padding-top: 0;
  padding-right: 16px;
  text-decoration: none;
  font-size: 2vw;
}
p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 0.1em;
  background-color: var(--primary);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

p:hover::after,
p:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

@media only screen and (max-width: 1000px) {
  li {
    margin: 0 1vw;
  }
  li p {
    font-size: 3.5vw;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 600px) {
  li {
    margin: 0 0.2vw;
  }
}
